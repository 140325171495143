import React from 'react';
import styles from './objectItemCard.module.css';
import { useSelector } from 'react-redux';
// import {auctionGetId} from '../../../../../redux/actions/auctions/auctions';
import {Link, useNavigate} from 'react-router-dom';
// import { useDispatch } from 'react-redux';
import InfoModal from '../../product/infoIconBlock/infoIconBlock'
import { checkProp,
    numFormat,
    getCurrency,
    getAddedTax,
    dateTransform,
    isMongo
} from '../../../../../utils/check'
import moment from 'moment';
import thumbnailApi from "../../../../api/thumbnailsApi";

const ObjectItemCard = ( { item, ...props} ) => {
    const user = useSelector(state => state.start.userProfiles)
    // const dispatch = useDispatch()
    const navigate = useNavigate()

    let balanceHolder = checkProp(item.relatedOrganizations, 'propertyOwner') && checkProp(item.relatedOrganizations.propertyOwner, 'name') ? item.relatedOrganizations.propertyOwner.name.uk_UA : null //балансоутримувач 
    let objList = checkProp(item, 'registryObjectItems') ? item.registryObjectItems : []
    let publishDate = checkProp(item,'datePublished') ? item.datePublished : null// дата публікації
    let documents = checkProp( item, 'documents') ? item.documents : null// Термін
    let propertyType = !!item?.registryObjectItems && item?.registryObjectItems.length > 0 && getPropertyType(item.registryObjectItems[0].itemType)

    function getLeaseDuration() {
        if (checkProp(item.leaseRules, 'leaseDuration')) {
            const dur = moment.duration(item.leaseRules.leaseDuration)
            let tmpYears = dur._years
            let tmpMonth = dur._months
            let tmpDays = dur._days
            return (
                <>
                    <div className={styles.costText}>Термін оренди</div>
                    <div className={styles.rentPeriod}>
                        {tmpYears > 0 ? `${tmpYears} років` : null}
                        {tmpMonth > 0 ? ` ${tmpMonth} місяців` : null}
                        {tmpDays > 0 ? ` ${tmpDays} днів` : null}
                    </div>
                </>
            )
        } else {
            return  (
                <>
                    <div className={styles.costText}>Термін оренди</div>
                    <div className={styles.rentPeriod}>не вказано</div>
                </>
            )
        }
    }

    // фотка или нет ее
    function avatarKa( docs) {
        const images = docs?.filter(i => i.documentType.includes('illustration'))
        if( images.length > 0 ) {
            return(
                <div className={styles.avatar}>
                    <div className={styles.avatarText}>
                            { images.length }
                        <br />
                            фото
                    </div>
                    <img src={thumbnailApi.getDocumentThumbnailLink(images[0], 64, 64)}
                         onError={(i) => {
                             i.target.style.display = "none"
                         }}
                         alt={images.length + " фото"}/>
                </div>
            )
        } else {
            return <div className={styles.unactive}></div>
        }
    }

    //бейдж "Ваш обьект"
    function yourObjectBadge() {
        if ( !!user &&
            ( ( 
                !!item?.relatedOrganizations?.propertyOwner?.identifier?.id && 
                !!item?.relatedOrganizations?.sellingEntity?.identifier?.id && 
                !!user?.organization?.identifier?.identifier 
                ) && 
                    item.relatedOrganizations?.propertyOwner?.identifier?.id === user?.organization?.identifier?.identifier ||
                    item.relatedOrganizations?.sellingEntity?.identifier?.id === user?.organization?.identifier?.identifier 
            )
        ) { return <div className={styles.yourObjBadge}>Ваш об’єкт</div> }
        // else return null 
    }

    //статус
    function setStatus(a){
        if(a === undefined || a === 'undefined' || a === null) { return ''} 
            else {
                switch(a) {
                    case 'waiting':
                        return <div className={`${styles.rightBlock_Status} ${styles.colorMeedllGrey}`}>
                                Очікується
                                </div>
                    case 'approved':
                        return <div className={`${styles.rightBlock_Status} ${styles.colorGreen}`}>
                            Включено в перелік
                            </div>
                    case 'inactive':
                        return <div className={`${styles.rightBlock_Status} ${styles.colorLightGrey}`}>
                            Неактивний
                            </div>
                    case 'active':
                        return <div className={`${styles.rightBlock_Status} ${styles.colorGreen}`}>
                        Опубліковано оголошення
                        </div>
                    case 'qualification':
                        return <div className={`${styles.rightBlock_Status} ${styles.colorLightBrown}`}>
                        Визначення орендаря
                        </div>
                    case 'sold':
                        return <div className={`${styles.rightBlock_Status} ${styles.colorMeedllGrey}`}>
                        Орендовано
                        </div>
                    default:
                        return ''
                }
            }
    }

    function getPropertyType(a) {
        switch(a) {
            case 'realEstate':
                return `Об'єкт нерухомості`
            case 'vehicle':
                return `Транспортний засіб`
            case 'land':
                return `Земельна ділянкa`
            case 'otherProperty':
                return `Інше окреме індивідуально визначене майно`
            case 'jointPropertyComplex':
                return `Об'єкт єдиного майнового комплексу`
            default:
                return a
        }
    }
 
    //тип переліку
    function registryListType(a) {
        switch(a) {
            case 'Undefined':
                return (
                    <div className={styles.objType}><span>Тип переліку: </span>Не визначено</div>
                )

            case 'First':
                return (
                    <div className={styles.objType}><span>Тип переліку: </span>Перший ступінь</div>
                )

            case 'Second':
                return (
                    <div className={styles.objType}><span>Тип переліку: </span>Другий ступінь</div>
                )
        }
    }

    function getAmount() {
        if (checkProp(item, 'valuesCharacteristics')) {
            if (checkProp(item.valuesCharacteristics, 'secondListAbsoluteValue')) {
            
                let blockTmp = item.valuesCharacteristics.secondListAbsoluteValue
                let amount = checkProp(blockTmp, 'amount') ? numFormat.format(blockTmp.amount) : ' не вказано'

                return (
                    <>
                        <div className={styles.costText}>{`Орендна ставка, ${getAddedTax(blockTmp.valueAddedTaxIncluded)}`}
                        </div>
                        <div className={styles.costSumm}>
                            {`${amount} ${getCurrency(blockTmp.currency)}`}
                        </div>
                    </>
                )
            }
        } else {
            return (
                <>
                    <div className={styles.costText}>{`Орендна ставка`}
                    </div>
                    <div className={styles.costSumm}>
                        {`не вказано`}
                    </div>
                </>
            )
        }
    }

    return(
        <>
            <div className={styles.objItem}>
                <div className={styles.leftBlock}>
                    { !!item && avatarKa( item.documents ) }
                </div>
                <div className={styles.centralBlock}>
                    <div className={styles.topBlock}>
                        <div className={styles.objIdAndType}>
                            <Link to={`/lease/object/${item.registryObjectId}`}>
                                <div className={styles.objIdAndType_id}>
                                    {item.registryObjectId}
                                </div>
                            </Link>
                            { !!item && yourObjectBadge() }
                            <div className={styles.objIdAndType_type}>{propertyType}</div>
                        </div>
                            <Link to={`/lease/object/${item.registryObjectId}`}>
                                <div className={styles.objTitle} >
                                    { !!item?.title && isMongo(item.title) }
                                </div>
                            </Link>
                        <div className={styles.objDiscription}>
                            { objList.map( item => <li key={item.id}>{ isMongo(item.basicInfo.title) }</li> ) }
                        </div>
                        {/* <div className={styles.objMore}>Більше</div> */}
                        {
                            checkProp(item, 'statusesDecisions') && checkProp(item.statusesDecisions, 'listType')
                                && registryListType(item.statusesDecisions.listType)
                        }
                    </div>
                    <div className={styles.bottomBlock}>
                        <div className={styles.objOwner}>
                            <span>Балансоутримувач:</span>
                            {balanceHolder}
                            { checkProp(item.relatedOrganizations, 'propertyOwner') ? 
                                <InfoModal
                                info={item.relatedOrganizations.propertyOwner}
                                /> : null
                            }
                        </div>
                        <div className={styles.objRenter}>
                            <span>Орендодавець:</span>
                            { !!item?.relatedOrganizations?.sellingEntity?.name && isMongo( item.relatedOrganizations.sellingEntity.name ) }
                            { checkProp(item.relatedOrganizations, 'sellingEntity') ?
                                <InfoModal
                                info={item.relatedOrganizations.sellingEntity}
                                /> : null
                            }
                        </div>
                    </div>
                </div>
                <div className={styles.rightBlock}>
                    { !!item?.statusesDecisions?.statusInList && setStatus( item.statusesDecisions.statusInList ) }
                    <div className={styles.rightBlock_Date}>
                        <div className={styles.rightBlock_Start}>
                            <div className={styles.rightBlock_date_text}>Дата публікації</div>
                            <div className={styles.rightBlock_date_time}>{dateTransform(publishDate)}
                                <span className={styles.rightBlock_date_timeSpan}>{dateTransform(publishDate, 'onlyTime')}</span>
                            </div>
                        </div>
                            <div className={styles.rightBlock_Start}>
                                <div className={styles.rightBlock_date_text}>Можливість суборенди
                                </div>
                                <div className={styles.rightBlock_date_txt}>
                                    { !!item?.leaseRules?.subleaseAllowed ? 'Присутня' : 'Відсутня'}
                                </div>
                            </div>
                    </div>
                    <div className={styles.rightBlock_CostAndButtons}>
                        {getLeaseDuration()}
                        {getAmount()}
                    </div>
                    <div className={styles.buttonsLine}>
                        {/* <Link to={`/lease/object/${id}`}> */}
                            <div className={styles.buttonView} onClick={ ()=> navigate(`/lease/object/${item.registryObjectId}`) }>
                                    Переглянути
                            </div>
                        {/* </Link> */}
                    </div>
                </div>
            </div> 
        </>
    )
    
}

export default ObjectItemCard