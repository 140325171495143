import React,{ useEffect } from 'react';
import styles from './generalInfoBlock.module.css';
import { useDispatch, useSelector } from 'react-redux';

import FullInfoBlock from '../fullInfoBlock/fullInfoBlock'
import HalfInfoBlock from '../halfInfoBlock/halfInfoBlock'
import InfoBlockСollapser from '../infoBlockСollapser/infoBlockСollapser'
import ViewDocItem from '../../../../elements/documents/view/viewDocumentItem';
// import DocItem from '../docItem/documentItem'
import InstructionBlock from '../instructionBlock/instructionBlock'
import ViewLeaseRules from './leaseRules/viewLeaseRules';
import ObjectItem from '../objectItem/objItem';
import ManageOrganization from './manageOrganizations/manageOrganization';
import ManageOrganizations from './manageOrganizations/manageOrganizations';
import { objectGetListTypes, objectGetStatusInList, 
    objectGetRegistrationStateList, objectGetVerification3List,
    } from '../../../../../redux/actions/object/object.js';
import { checkProp, dateTransform, isMongo, getRegistryType, 
    getRegistrationStatus, getCurrency, getTranslate } from '../../../../../utils/check';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../elements/buttons/button';
import { getLeaseObjectDocTypes } from '../../../../../utils/objects/objectsUtils';

const GeneralInfo = ({ data, setPath }) => {
    const numFormat = new Intl.NumberFormat()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const objectListTypesList = useSelector(state => state.start.objectListTypesList);
    const objectStatusInList = useSelector(state => state.start.objectStatusInList);
    const objectRegistrationStateList = useSelector(state => state.start.objectRegistrationStateList);
    const objectVerification3List = useSelector(state => state.start.objectVerification3List);

    useEffect(() => {
        // alert('111')
        if( !objectListTypesList ) {
            dispatch( objectGetListTypes(`${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/dictionaries/listType`));
        }
        if( !objectStatusInList ) {
            dispatch( objectGetStatusInList(`${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/dictionaries/statusInList`));
        }
        if( !objectRegistrationStateList ) {
            dispatch( objectGetRegistrationStateList(`${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/dictionaries/registrationState`));
        }
        if( !objectVerification3List ) {
            dispatch( objectGetVerification3List(`${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/dictionaries/verification3`));
        }
    }, []);

    function getPropertyType() { // тип об'єкта
        if(checkProp(data, 'registryObjectItems')) {
            let a = data.registryObjectItems['0'].itemType
            switch(a) {
                case 'realEstate':
                    return `Об'єкт нерухомості`
                case 'vehicle':
                    return `Транспортний засіб`
                case 'land':
                    return `Земельна ділянкa`
                case 'otherProperty':
                    return `Інше окреме індивідуально визначене майно`
                case 'jointPropertyComplex':
                    return `Об'єкт єдиного майнового комплексу`
                default:
                    return a
            }
        }
    }

    function getObjectStatus(a){ // статус об'єкта
                switch(a) {
                    case 'waiting':
                        return  'Очікується'
                    case 'approved':
                        return 'Включено в перелік'
                    case 'inactive':
                        return 'Неактивний'
                    case 'active':
                        return 'Опубліковано оголошення'
                    case 'qualification':
                        return 'Визначення орендаря'
                    case 'sold':
                        return 'Орендовано'
                    default:
                        return ''
                }
    }

    function getOwnershipType(a) { // Тип власності
        switch(a){
            case 'state':
                return 'Державна'
            case 'private':
                return 'Приватна'
            case 'municipal':
                return 'Комунальна'
            case 'compatible':
                return 'Сумісна'
            case 'unknown':
                return 'Невідомо'
        }
    }

    function getRegisrtyDecisions() { //рішеня щодо об'єкта
        if (checkProp(data, 'registryDecision') && data.registryDecision.verificationPresence) {
            let doc = data.registryDecision.x_verificationDocuments
            return (<>
                <HalfInfoBlock
                    title='Наявність рішення про затвердження переліку об’єктів, або про включення нового об’єкта до переліку'
                    data={'Присутня'}
                />
                <HalfInfoBlock
                    title='Реквізити підтверджуючого документу'
                    data={ doc ? doc : '-'}
                />
            </>)
        }
    }

    function getValueCharacteristics(data) { // вартісні характеристики

        if ( !!data ) {
            return (<>
                        <InfoBlockСollapser 
                            blockTitle='Вартісні характеристики'
                            collapsed={false}
                            content={
                                <>
                                   { !!data?.initialBookValue?.amount ?
                                    <HalfInfoBlock 
                                        title={`Первісна балансова вартість, ${getCurrency(data.initialBookValue.currency)} ${data.initialBookValue.valueAddedTaxIncluded ? 'з' : 'без'} ПДВ`} 
                                        data={data.initialBookValue.amount} />
                                : null }
                                { !!data?.residualBookValue?.amount ?
                                    <HalfInfoBlock 
                                        title={`Залишкова балансова вартість, ${getCurrency(data.residualBookValue.currency)} ${data.residualBookValue.valueAddedTaxIncluded ? 'з' : 'без'} ПДВ`} 
                                        data={data.residualBookValue.amount} />
                                : null }
                                { !!data?.marketValue?.amount ?
                                    <HalfInfoBlock 
                                        title={`Ринкова вартість, ${getCurrency(data.marketValue.currency)} ${data.marketValue.valueAddedTaxIncluded ? 'з' : 'без'} ПДВ`} 
                                        data={data.marketValue.amount} />
                                : null }
                                { !!data?.marketValueValuationDate ?
                                    <HalfInfoBlock 
                                        title="Дата визначення ринкової вартості" 
                                        data={dateTransform(data.marketValueValuationDate)} />
                                : null }
                                { !!data?.marketValueValuationDetails ?
                                    <FullInfoBlock 
                                        title="Інформація про оцінювача, вартість оцінки, необхідність компенсації оцінки орендарем та суму компенсації" 
                                        data={ data.marketValueValuationDetails } />
                                : null }
                                { !!data?.secondListMethodology  ?
                                    <FullInfoBlock 
                                        title="Посилання на пункт Методики розрахунку орендної плати, яким встановлена орендна 
                                        ставка для запропонованого цільового призначення" 
                                        data={ data.secondListMethodology } />
                                : null }
                                { !!data?.secondListAbsoluteValue?.amount ?
                                    <HalfInfoBlock
                                        title={`Фактичне значення орендної ставки, ${getCurrency(data.secondListAbsoluteValue.currency)} ${data.secondListAbsoluteValue.valueAddedTaxIncluded ? 'з' : 'без'} ПДВ`} 
                                        data={ data.secondListAbsoluteValue.amount } />
                                : null }
                                { !!data?.secondListPercentValue ?
                                    <HalfInfoBlock 
                                        title="Значення орендної ставки у відсотках" 
                                        data={ data.secondListPercentValue } />
                                : null }
                                { !!data?.secondListDecisionDescription ?
                                    <FullInfoBlock 
                                        title="Інші відомості щодо визначення орендної ставки" 
                                        data={ data.secondListDecisionDescription } />
                                : null }
                                </>
                        }
                        /> 
                    </>
            )
        }
    }

    function getDecisionsStatuses( data ) {
        if ( !!data ) {

            return (
                <>
                    {checkProp(data, 'listType') &&
                            <HalfInfoBlock
                                title='Тип переліку, до якого віднесено об`єкт'
                                data={getTranslate(data.listType, objectListTypesList)} 
                            />
                        }
                        {checkProp(data,'statusInList') &&
                            <HalfInfoBlock 
                                title='Статус об`єкту в переліку'
                                data={getTranslate(data.statusInList, objectStatusInList)} 
                            />
                        }
                        {checkProp(data,'registrationState') &&
                            <HalfInfoBlock 
                                title='Стан державної реєстрації об`єкту'
                                data={getTranslate(data.registrationState, objectRegistrationStateList)} 
                            />
                        }
                        { checkProp(data,'registrationRequisites') &&
                            <HalfInfoBlock 
                                title='Реквізити державної реєстрації об`єкту'
                                data={data.registrationRequisites}
                            />
                        }
                        <HalfInfoBlock 
                            title='Чи є об`єкт пам`яткою культурної спадщини'
                            data={data.culturalHeritage ? 'Так' : 'Ні'}
                        />
                        { !!data?.culturalHeritage &&
                                checkProp(data, 'culturalHeritageCondition') ?
                                    <HalfInfoBlock
                                        title='Стан пам`ятки культурної спадщини'
                                        data={data.culturalHeritageCondition}
                                    /> : null
                        }
                        { !!data?.heritageApproval?.verificationPresence &&
                            <div className={styles.groupWrapper}>
                                <HalfInfoBlock 
                                    title='Наявність та необхідність рішення про погодження органу охорони культурної спадщини'
                                    data={ getTranslate(data.heritageApproval.verificationPresence, objectVerification3List) }
                                />
                                {data.heritageApproval.verificationPresence === 'present' ?
                                    <HalfInfoBlock
                                        title='Реквізити підтверджуючого документу'
                                        data={data.heritageApproval.x_verificationDocuments}
                                    /> : null
                                }
                            </div>
                        }
                        { !!data?.propertyOwnerApproval?.verificationPresence &&
                            <>
                                <HalfInfoBlock 
                                    title='Наявність рішення про намір передачі майна в оренду'
                                    data='Присутня' />
                                <HalfInfoBlock 
                                    title='Реквізити підтверджуючого документу'
                                    data={data.propertyOwnerApproval.x_verificationDocuments} />
                            </>
                        }
                        { !!data?.sellingEntityApproval?.verificationPresence &&
                            <>
                                <HalfInfoBlock 
                                    title='Наявність рішення про включення в перелік'
                                    data='Присутня' />
                                <HalfInfoBlock 
                                    title='Реквізити підтверджуючого документу'
                                    data={data.sellingEntityApproval.x_verificationDocuments} />
                            </>
                        }
                        { !!data?.governingEntityApproval?.verificationPresence && 
                            <>
                                <HalfInfoBlock 
                                    title='Погодження уповноваженого органу управління'
                                    data={ getTranslate(data.governingEntityApproval.verificationPresence, objectVerification3List) } 
                                />
                                    { data.governingEntityApproval.verificationPresence === 'present' &&
                                        <HalfInfoBlock
                                            title='Реквізити підтверджуючого документу'
                                            data={data.governingEntityApproval.x_verificationDocuments}
                                        />
                                    }
                            </>
                        }
                        { !!data?.maintenanceRepairAgreement?.verificationPresence && 
                            <>
                                <HalfInfoBlock 
                                    title='Інформація про згоду на здійснення поточного та/або капітального ремонту орендованого майна'
                                    data={data.maintenanceRepairAgreement.verificationPresence  ? 'Так' : 'Ні'} 
                                />
                                { !!data?.maintenanceRepairAgreement &&
                                    <HalfInfoBlock 
                                        title='Реквізити підтверджуючого документу'
                                        data={data.maintenanceRepairAgreement.x_verificationDocuments}
                                    /> 
                                }
                            </>
                        }
                        { !!data?.privatizationDecision?.verificationPresence &&
                            <>
                                <HalfInfoBlock 
                                    title='Наявність рішення про включення об’єкта до переліку майна, що підлягає приватизації'
                                    data={data.privatizationDecision.verificationPresence  ? 'Присутнє' : 'Вісутнє'} />
                                <HalfInfoBlock 
                                    title='Реквізити підтверджуючого документу'
                                    data={data.privatizationDecision.x_verificationDocuments} />
                            </>
                        }
                        { !!data?.investmentDecision?.verificationPresence &&
                            <>
                                <HalfInfoBlock 
                                    title='Наявність рішення про проведення інвестиційного конкурсу'
                                    data='Присутня'
                                />
                                <HalfInfoBlock 
                                    title='Реквізити підтверджуючого документу'
                                    data={data.investmentDecision.x_verificationDocuments}
                                />
                            </>
                        }
                </>
            )
        }
    }

    function getDocuments() {
        if (checkProp(data, 'documents')) {
            let tmp = data.documents.map(( item ) => {
                 return (
                    <ViewDocItem
                        key={item.id}
                        data={item}
                        specs={{
                            objId: data.id,
                            docType: getLeaseObjectDocTypes( false, item.documentType )
                            // historyURL: `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/asset/${id.annId}/document/${id.docId}/history`
                        }}
                    />
                )
            })
            return <div style={{display: 'flex', flexFlow: 'column', gap: '10px', width: '100%'}}>{tmp}</div>
        }
    }

    return (
        <div className={styles.blockWrap}>
            <div className={styles.infoBlock + ' ' + styles.generalInfo}>
                <div className={styles.infoBlockTitle}>
                    <h3>Загальна інформація</h3>
                </div>
                {/* {!!data.title &&
                    <FullInfoBlock
                        title='Назва об`єкту реєстра'
                        data={ isMongo(data.title)}
                    />
                } */}
                <FullInfoBlock
                    title='Тип об`єкту в реєстрі'
                    data={getPropertyType()}
                />
                <HalfInfoBlock
                    title='Дата публікації'
                    data={ dateTransform(data.datePublished, 'time') }
                />
                <HalfInfoBlock
                    title='Дата останньої зміни'
                    data={ dateTransform(data.dateModified, 'time') }
                />
                {getRegisrtyDecisions()}
            </div>
            { getValueCharacteristics(data.valuesCharacteristics) }
            {checkProp(data,'registryObjectItems') && data.registryObjectItems.length > 0 ? <InfoBlockСollapser 
                blockTitle={`Перелік об'єктів`}
                collapsed={true}
                content={
                    <>
                        {
                            data.registryObjectItems.map( (item) =>{
                                    return <ObjectItem
                                                data={item}
                                                key={item.id}
                                            />
                            })
                        }
                    </>
                }
            /> : null}
            <InfoBlockСollapser 
                blockTitle={`Правила та умови передачі об'єкта в оренду`}
                blockDescription={`Дані про графік оренди та наявні обмеження цільового використання`}
                collapsed={true}
                content={
                    <>
                        <ViewLeaseRules data={data.leaseRules} />
                    </>
                }
            />
            {checkProp(data,'relatedOrganizations') ?
                <InfoBlockСollapser 
                    blockTitle={`Організації, задіяні в керуванні об' єктом`}
                    // blockDescription={``}
                    collapsed={true}
                    content={
                        <>
                            { checkProp(data.relatedOrganizations, 'ownershipType')
                                ? <HalfInfoBlock
                                    title={`Тип власності`}
                                    data={getOwnershipType(data.relatedOrganizations.ownershipType)}
                                    /> 
                                : null
                            }
                            {checkProp(data.relatedOrganizations,'propertyOwner') ? 
                                <ManageOrganization 
                                    title={'Балансоутримувач'} 
                                    data={data.relatedOrganizations.propertyOwner} 
                                /> : null 
                            }
                            {checkProp(data.relatedOrganizations,'sellingEntity') ? 
                                <ManageOrganization 
                                    title={'Орендодавець'} 
                                    data={data.relatedOrganizations.sellingEntity} 
                                /> : null
                            }
                            {checkProp(data.relatedOrganizations,'currentTenants') && data?.relatedOrganizations?.currentTenants.length > 0
                                ? <ManageOrganizations 
                                    title={'Чинний орендар'} 
                                    data={data.relatedOrganizations.currentTenants} 
                                />
                                : null
                            }
                            {checkProp(data.relatedOrganizations,'governer') && data?.relatedOrganizations?.governer !== null ? 
                                <ManageOrganization 
                                    title={'Уповноважений орган управління'} 
                                    data={data.relatedOrganizations.governer} 
                                /> : null
                            }
                        </>
                    }
                /> : null 
            }
            <InfoBlockСollapser 
                blockTitle={`Дані про наявність офіційних рішень, статусів тощо`}
                collapsed={true}
                content={
                    <>
                        { getDecisionsStatuses( data.statusesDecisions ) }
                    </>
                }
            />
            { (checkProp(data, 'documents') && data.documents.length > 0) 
                    &&
                <div className={styles.infoBlock}>
                    <div className={styles.infoBlockTitle}>
                        <h3>Перелік документів ({data.documents.length})</h3>
                    </div>
                        { getDocuments() }
                </div>
            }
            {/* <InstructionBlock /> */}
                <div className={styles.bottomBtnBlock}>
                    <Button onClick={ ()=> navigate('lease_requests')}>Заяви до обʼєкту</Button>
                    <Button onClick={ ()=> navigate('lease_actions')}>Повʼязані дії</Button>
                </div>
        </div>
    )
}

export default GeneralInfo