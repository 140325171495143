import React, { useState } from 'react';
import styles from './documents.module.css';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import ObjectPublish from '../../publishBtn/publishBtn'

import { documentDelete } from '../../../../../../redux/actions/documents/documents';
import { myDraftObjectDocumentPost, myObjectDocumentPost, myDraftObjectDocument, myObjectDocument } from '../../../../../../utils/endPoints';
import AddDocPopUp from '../../../../../elements/documents/addDocPopup/addDocPopup';
import CreateDocItem from '../../../../../elements/documents/docItem/documentItem';
import { getLeaseObjectDocTypes } from '../../../../../../utils/objects/objectsUtils';

const Documents = ( { isDraft } ) => {

    const dispatch = useDispatch();
    const data = useSelector(state => state.start.objectGet);
    const token = useSelector(state => state.start.token);
    const [ addDocPopup, setAddDocPopup ] = useState( false );

    const docProps = {
        dispatch: ( res )=> dispatch( { payload: res, type: 'OBJECT_GET'}),
        docOf: 'object',
        object: data,
        docList: getLeaseObjectDocTypes( true ),
        url: {
            patch: isDraft ? myDraftObjectDocument : myObjectDocument,
            post: isDraft ? myDraftObjectDocumentPost : myObjectDocumentPost,
            delete: ( id ) => dispatch( documentDelete( ( isDraft ? myDraftObjectDocument(data.id, id) : myObjectDocument(data.id, id) ), token.access_token ) )
        }
    }

    function showDocuments(documents) {
        if (documents && documents.length > 0) {
            // return documents.map( (item) => <DocumentItem key={item.id} data={item} />);
            return documents.map( (item) => <CreateDocItem  key={item.id} data={item} docProps={docProps} />);
        }
        return null;
    }



    return(<>
        <div className={styles.wrapper}>
            <div className={styles.title}>Перелік документів</div>
            {data !== null ? <>
                <div className={styles.docListContainer}>
                    { showDocuments( data.documents ) }
                </div>
                <button className={styles.buttonAdd} onClick={ () => setAddDocPopup(true) }>Додати документ</button>
                <div className={styles.buttonWrapper}>
                    <Link to={`/lease/my_objects`} className={styles.buttonCancel}>До списку переліків</Link>
                </div>
            </> : null}
        </div>
        { addDocPopup && 
            <AddDocPopUp close={ ()=> setAddDocPopup( false ) } docProps={ docProps } />
        }
    </>);
}

export default Documents;