const thumbnailApi = {
    getDocumentThumbnailLink: function getDocumentThumbnailLink(document, width, height) {
        let id = document._ds_id ?? document.id
        let url = `${process.env.REACT_APP_ENDPOINT_PROZORO_THUMBNAILS}/${document?._ds_scope ?? 'public'}/${id}`
        if (width) url += `&w=${width}`
        if (height) url += `&h=${height}`
        // console.log(url)
        return url
    }
}

export default thumbnailApi
