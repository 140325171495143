import React, { useState, useEffect } from 'react';
import axios from "axios"
import styles from './documentItem.module.css';
import Button from '../../../elements/buttons/button';
import { checkProp, dateTransform, isMongo, getShorter } from '../../../../utils/check';
import ViewImageIcon from '../../../elements/svg/viewImagelIcon';
import ImgViewPopUp from '../../../popups/imgViewPopUp/imgViewPopUp'
import HistoryIcon from '../../../elements/svg/historylIcon';
import DownLoadIcon from '../../../elements/svg/downloadIcon';
import UniPopUp from '../../../popups/uniPopUp/uniPopUp';
import HalfInfoBlock from '../../../elements/halfInfoBlock/halfInfoBlock';

    const infoModalBlock = ( data, close )=> { return <>
        <div className={styles.wrapperInfo} onClick={close}></div>
            <div className={styles.inputInfoBlock}>
                <div className={styles.modDocTitle}>{ isMongo(data.title) }</div>
                {checkProp(data, 'datePublished') ?
                    <div className={styles.infoField}>
                        Дата створення:<span>{dateTransform(data.datePublished, 'time')}</span>
                    </div>
                    : null
                }
                {checkProp(data, 'dateModified') ?
                    <div className={styles.infoField}>
                        Дата редагування:<span>{dateTransform(data.dateModified, 'time')}</span>
                    </div>
                    : null
                }
                { data?.documentType && data.documentType === 'illustration' ?
                    <div className={styles.infoField}>
                        Тип документу:<span>
                            Зображення
                        </span>
                    </div>
                    : null
                }
                { data?.format &&
                    <div className={styles.infoField}>
                        Формат:<span>{data.format}</span>
                    </div>
                }
                {checkProp(data, 'description') ?
                    <div className={styles.infoField}>
                        Опис документу:<span>
                            { isMongo(data.description) }
                        </span>
                    </div>
                    : null
                }
                <div className={styles.bottomBtnBlock}>
                    <Button 
                        color='blue'
                        height='28px'
                        onClick={ close }>
                            Зрозуміло
                    </Button>
                </div>
        </div>
    </>
    }

    const HistoryItem = ( { data } )=> {
        return (
            <div className={styles.historyItem}>
                <div className={styles.hisDocTitle}>{ isMongo( data.title ) }</div>
                <div className={styles.hisDocContent}>
                    <HalfInfoBlock
                        title={'Опис документу'}
                        data={ isMongo( data.description ) }
                    />
                    <HalfInfoBlock 
                        title={'Дата створення:'}
                        data={ dateTransform(data.datePublished, 'time') }
                    />
                    <Button type={'circle'}>
                        <a href={data.url} download={ isMongo(data.title) }>
                            <DownLoadIcon widthHeight={[16,20]} style={styles.svgIconDownld} />
                        </a>
                    </Button>
                </div>
                
            </div>
        )
    }

const ViewDocItem = ( { data, specs } ) => {
    const [svgInfo, setSvgInfo] = useState(false)
    const [imgView, setImgView] = useState(false)
    const [history, setHistory] = useState([])
    const [hisPopUp, setHisPopUp] = useState(false)

    useEffect(() => {
        if ( ( data.datePublished !== data.dateModified ) && !!specs?.historyURL ) {
            getDocumentHistory( specs.historyURL, setHistory )
        }
    }, [])

    //TODO: треба змінити колись або на і, -  затик з форматом
    return (
        <div className={styles.docList}>
            <div className={styles.docBlockLft}
                title={isMongo(data.title)}
            >
                { getShorter(isMongo(data.title)) }
            </div>
            <div className={styles.docBlockRgt}>
                { specs?.docType }
            </div>
            <div className={styles.iconsBlock}>
                { ( !!data?.url && ( data.format.includes('image') || data.documentType.includes('illustration')) ) && 
                    <Button type={'circle'} onClick={() => setImgView(true)} >
                        <ViewImageIcon 
                            title='переглянути зображення' 
                            style={styles.svgIconView}
                            widthHeight={[28,28]}
                        />
                    </Button>
                }
                {
                    history.length > 0 && 
                    <Button type={'circle'} color={'var(--darkGray)'} 
                        onClick={ () => setHisPopUp(true) }
                        title={'Переглянути історію змін'}
                    >
                            <HistoryIcon widthHeight={[30,30]} color={'var(--darkGray)'} />
                    </Button>
                }
                <div className={styles.infoWrap}>
                    <Button type={'circle'}>
                        <svg className={svgInfo ? styles.svgIconInfoActive : styles.svgIconInfo} width="20" height="20"
                            viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                            onClick={ ()=> setSvgInfo( true ) }
                        >
                            <path className={styles.svgIcon} d="M10.75 14.2001L10.75 9.45014C10.75 8.92547 10.3247 8.50014 9.80003 8.50014C9.27536 8.50014 8.85003 8.92547 8.85003 9.45014L8.85003 14.2001C8.85003 14.7248 9.27536 15.1501 9.80003 15.1501C10.3247 15.1501 10.75 14.7248 10.75 14.2001Z" fill="#92929D"/>
                            <path className={styles.svgIcon} d="M8.85003 6.60014C8.85003 6.07547 9.27536 5.65014 9.80003 5.65014C10.3247 5.65014 10.75 6.07547 10.75 6.60014C10.75 7.12481 10.3247 7.55014 9.80003 7.55014C9.27536 7.55014 8.85003 7.12481 8.85003 6.60014Z" fill="#92929D"/>
                            <path className={styles.svgIcon} fillRule="evenodd" clipRule="evenodd" d="M0.300034 10.4001C0.300034 5.15343 4.55333 0.900139 9.80003 0.900139C15.0467 0.900139 19.3 5.15343 19.3 10.4001C19.3 15.6469 15.0467 19.9001 9.80003 19.9001C4.55333 19.9001 0.300034 15.6469 0.300034 10.4001ZM17.4 10.4001C17.4 6.20278 13.9974 2.80014 9.80003 2.80014C5.60267 2.80014 2.20003 6.20278 2.20003 10.4001C2.20003 14.5975 5.60267 18.0001 9.80003 18.0001C13.9974 18.0001 17.4 14.5975 17.4 10.4001Z" fill="#92929D"/>
                            
                        </svg>
                    </Button>
                    
                </div>
                {!!data.url &&
                    <Button type={'circle'}>
                        <a href={data.url} download={ isMongo(data.title) }>
                            <DownLoadIcon widthHeight={[16,20]} style={styles.svgIconDownld} />
                        </a>
                    </Button>
                }
            </div>
            { svgInfo && infoModalBlock(data, ()=> setSvgInfo( false )) }
            { imgView && 
                <ImgViewPopUp 
                    close={() => setImgView(false)}
                    data={data} 
                /> 
            }
            { hisPopUp && 
                <UniPopUp 
                    close={() => setHisPopUp(false)}
                    data={ 
                    <div className={styles.historyContainer} >
                        <div>Попередні версії документу { specs.docType } </div>
                        <div className={styles.historyList} >{ history.map( i => <HistoryItem data={i} key={i.id} /> )}</div>
                    </div> }
                />
            }
        </div>
    )
}

async function getDocumentHistory( url, setData ) {
    try {
        const data = await axios.get( url, 
        { headers: {
            // 'Authorization': token,
            'Content-Type': 'application/json'
        } })
        setData(data.data)
    } catch (err) {
    }
}

// specs={{
//     objId: data.id,  --> тут нам треба айді обєкта
//     docType: getAnnouncementDocType(doc.documentType) --> тут ми дістаємо тип документа
//     historyURL: `${process.env.REACT_APP_END_POINT_BD}/api/v2/uk/public/asset/${id.annId}/document/${id.docId}/history`
// }}
//TODO: треба доробити функціонал перегляду ЄЦП


export default ViewDocItem